import MonitorLayout from '@/Layouts/Monitor'
import { COMPANY_NAME } from '@/constants/envs/constants'
import { useMonitorOrderBook } from '@/hooks/useMonitorOrderBook'
import _ from 'lodash'
import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

interface IRouteParams {
  monitorNo: string
  [key: string]: any
}

const formatValue = (value: number | undefined | null): string => {
  return value === 0 || _.isNil(value) ? '-' : value.toString()
}

const ResponsiveHeading = styled.h1`
  font-size: 3vh;
`

const ResponsiveVehicleLicensePlateTitle = styled.p`
  font-size: 5vh;
`

const ResponsiveVehicleLicensePlateId = styled.div`
  font-size: 8vh;
`

const ResponsiveOrder = styled.table`
  font-size: 8vh;
`

export const MonitorDisplay: FC = () => {
  const { monitorNo } = useParams<IRouteParams>()
  const activeOrder = useMonitorOrderBook(Number(monitorNo))

  useEffect(() => {
    document.title = `Monitor ${monitorNo} ${COMPANY_NAME}`
  }, [monitorNo])

  return (
    <MonitorLayout>
      <div className="flex flex-col items-center bg-white h-screen w-screen">
        <div className="flex self-start items-end gap-5 bg-monitorBackgroundHeader w-full p-4">
          <ResponsiveHeading className="font-bold">
            Monitor {monitorNo}
          </ResponsiveHeading>
          <ResponsiveHeading className="font-bold">
            Order ID: {activeOrder?.id ?? '-'}
          </ResponsiveHeading>
        </div>
        <div className="flex flex-col bg-monitorBackgroundContent w-full h-full justify-center items-center">
          {activeOrder ? (
            <>
              <div className="text-center mb-10">
                <ResponsiveVehicleLicensePlateTitle className="mb-3">
                  ทะเบียนรถยนต์
                </ResponsiveVehicleLicensePlateTitle>
                <ResponsiveVehicleLicensePlateId className="bg-green-200 font-bold px-4 py-3 rounded-xl border-4 border-green-400">
                  {activeOrder ? activeOrder.vehicle_license_plate_id : ''}
                </ResponsiveVehicleLicensePlateId>
              </div>
              <ResponsiveOrder className="table-auto border-collapse border border-black">
                <tbody>
                  <tr>
                    <td className="border border-black px-4 py-2 text-center align-middle min-w-32">
                      ญ
                    </td>
                    <td className="border border-black px-4 py-2 text-center align-middle min-w-32">
                      {formatValue(activeOrder?.large_ice_cube_amount)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-4 py-2 text-center align-middle">
                      ล
                    </td>
                    <td className="border border-black px-4 py-2 text-center align-middle">
                      {formatValue(activeOrder?.small_ice_cube_amount)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-4 py-2 text-center align-middle">
                      ม
                    </td>
                    <td className="border border-black px-4 py-2 text-center align-middle">
                      {formatValue(activeOrder?.crushed_ice_amount)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-4 py-2 text-center align-middle">
                      มย
                    </td>
                    <td className="border border-black px-4 py-2 text-center align-middle">
                      {formatValue(activeOrder?.coarsely_crushed_ice_amount)}
                    </td>
                  </tr>
                </tbody>
              </ResponsiveOrder>
            </>
          ) : (
            <ResponsiveVehicleLicensePlateTitle>
              ยังไม่มีคำสั่งซื้อในขณะนี้
            </ResponsiveVehicleLicensePlateTitle>
          )}
        </div>
      </div>
    </MonitorLayout>
  )
}
