import { useEffect, useId, useRef } from 'react'
import { RealtimeChannel } from '@supabase/supabase-js'
import { buildFilterString } from '../realtime'
import { supabaseClient } from '@/configs/supabase'
import { Event, Filter, TableName } from '../supabase.interface'

export function useRealtimeChannel<T extends TableName, E extends Event>(
  event: E,
  table: T,
  filter: Filter<T> | null | undefined,
  onChange: (change: any) => void,
  onStatus?: (status: any, err?: Error) => void,
  onEnabled?: (enabled: boolean) => void,
  manualFilterString?: string
) {
  const filterString = filter
    ? buildFilterString(filter)
    : manualFilterString ?? undefined
  const channelId = `${table}-${useId()}`
  const channel = useRef<RealtimeChannel | undefined>()

  useEffect(() => {
    onEnabled?.(true)
    const opts = {
      event,
      schema: 'public',
      table,
      filter: filterString
    } as const
    const newChannel = (channel.current = supabaseClient.channel(channelId))

    newChannel
      .on('postgres_changes', opts as any, (change) => {
        // if we got this change over a channel we have recycled, ignore it
        if (channel.current === newChannel) {
          onChange(change as any)
        }
      })
      .subscribe((status, err) => {
        if (onStatus != null) {
          onStatus(status, err)
        } else {
          if (err != null) {
            console.error(err)
          }
        }
      })
    return () => {
      supabaseClient.removeChannel(newChannel)
      channel.current = undefined
    }
  }, [table, filterString])

  return channel
}
