import { combineReducers } from '@reduxjs/toolkit'
import application from './application/reducer'
import orderManager from './orderManager/reducer'

const reducer = combineReducers({
  application,
  orderManager
})

export default reducer
