import Header from '@/components/Header'
import Main from '@/components/Main/Default'
import { ToastApplication } from '@/components/UI/Container/Toast/Toast'
import { COMPANY_NAME } from '@/constants/envs/constants'
import React, { FC } from 'react'

interface ILayoutProps {
  children?: React.ReactNode
}

const Layout: FC<ILayoutProps> = (props: ILayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen relative bg-backGroundBase dark:bg-backGroundBase">
      <ToastApplication />
      <Header title={COMPANY_NAME} />
      <Main>{props.children}</Main>
    </div>
  )
}

export default Layout
