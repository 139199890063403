import { Card, CardGroup } from '@/components/UI/Container/Card'
import { useOrderBookStatusOpen } from '@/hooks/useOrderBookStatusOpen'
import { Row } from '@/libs/supabase/supabase.interface'
import { Button, Popconfirm, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import React, { FC, useCallback, useMemo } from 'react'
import { MonitorIcon } from 'lucide-react'
import {
  updateOrderComplete,
  switchOrderMonitorDisplay
} from '@/supabase/Orderbook/orderbook'
import { useAppDispatch } from '@/state/hooks'
import {
  setOrderFormStatus,
  setOrderIdForEdit
} from '@/state/orderManager/reducer'
import { CheckCircleTwoTone, ToolFilled } from '@ant-design/icons'

export const RightSection: FC = () => {
  const dispatch = useAppDispatch()
  const orderbooksStatusOpen = useOrderBookStatusOpen()

  const { orderMonitorOne, orderMonitorTwo } = useMemo(() => {
    const orderSort = _.orderBy(orderbooksStatusOpen, 'created_at', 'asc')
    return {
      orderMonitorOne: _.filter(orderSort, { monitor_number: 1 }),
      orderMonitorTwo: _.filter(orderSort, { monitor_number: 2 })
    }
  }, [orderbooksStatusOpen])

  const handleClickActiveOrder = useCallback(
    async (orderId: number, monitorNo: number, isMonitorDisplay: boolean) => {
      await switchOrderMonitorDisplay(orderId, monitorNo, isMonitorDisplay)
    },
    []
  )

  const handleClickConfirmOrder = useCallback(async (orderId: number) => {
    await updateOrderComplete(orderId)
  }, [])

  const handleClickOrderForUpdate = useCallback(
    (orderId: number) => {
      dispatch(setOrderFormStatus('UPDATE'))
      dispatch(setOrderIdForEdit(orderId))
    },
    [dispatch]
  )

  const columns: ColumnsType<Row<'OrderBook'>> = [
    {
      title: 'Monitor',
      dataIndex: 'is_monitor_display',
      key: 'is_monitor_display',
      align: 'center',
      width: 80,
      render: (isMonitorDisplay: boolean, record: Row<'OrderBook'>) => (
        <MonitorIcon
          size={30}
          color={
            isMonitorDisplay
              ? 'var(--color-monitor-screen-active-border)'
              : 'var(--color-monitor-screen-inactive-border)'
          }
          fill={
            isMonitorDisplay
              ? 'var(--color-monitor-screen-active-bg)'
              : 'var(--color-monitor-screen-inactive-bg)'
          }
          onClick={() =>
            handleClickActiveOrder(
              record.id,
              record.monitor_number,
              isMonitorDisplay
            )
          }
          className="m-auto cursor-pointer"
        />
      )
    },
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      width: 100
    },
    {
      title: 'License Plate',
      dataIndex: 'vehicle_license_plate_id',
      key: 'vehicle_license_plate_id',
      align: 'center',
      width: 100
    },
    {
      title: 'ใหญ่',
      dataIndex: 'large_ice_cube_amount',
      key: 'large_ice_cube_amount',
      width: 60,
      render: (amount) => (_.gt(amount, 0) ? amount : '-')
    },
    {
      title: 'เล็ก',
      dataIndex: 'small_ice_cube_amount',
      key: 'small_ice_cube_amount',
      width: 50,
      render: (amount) => (_.gt(amount, 0) ? amount : '-')
    },
    {
      title: 'โม่',
      dataIndex: 'crushed_ice_amount',
      key: 'crushed_ice_amount',
      width: 50,
      render: (amount) => (_.gt(amount, 0) ? amount : '-')
    },
    {
      title: 'มย',
      dataIndex: 'coarsely_crushed_ice_amount',
      key: 'coarsely_crushed_ice_amount',
      width: 50,
      render: (amount) => (_.gt(amount, 0) ? amount : '-')
    },
    {
      title: 'Confirm',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 80,
      render: (id: number) => (
        <Popconfirm
          title="Confirm order"
          description="Are you sure to confirm order?"
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            style: { fontSize: '16px' }
          }}
          cancelButtonProps={{
            style: { fontSize: '16px' }
          }}
          onConfirm={() => handleClickConfirmOrder(id)}
        >
          <Button icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
            Confirm
          </Button>
        </Popconfirm>
      )
    },
    {
      title: 'Edit',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 70,
      render: (id: number) => (
        <Button
          icon={<ToolFilled />}
          onClick={() => handleClickOrderForUpdate(id)}
        >
          Edit
        </Button>
      )
    }
  ]

  return (
    <CardGroup className="ml-5 gap-5 w-full">
      <Card className="flex-1 p-5">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={orderMonitorOne}
          pagination={false}
          scroll={{ y: 'calc(30dvh)' }}
        />
      </Card>
      <Card className="flex-1 p-5">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={orderMonitorTwo}
          pagination={false}
          scroll={{ y: 'calc(30dvh)' }}
        />
      </Card>
    </CardGroup>
  )
}
