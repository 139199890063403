import {
  Row,
  TableName,
  Change,
  REALTIME_TABLES,
  Filter
} from './supabase.interface'

export function buildFilterString<T extends TableName>(filter: Filter<T>) {
  return `${filter.k}=eq.${filter.v}`
}

export function applyChange<T extends TableName>(
  table: T,
  rows: Row<T>[],
  change: Change<T>
) {
  const spec = REALTIME_TABLES[table]
  if (spec == null) {
    throw new Error(
      'No key and timestamp columns specified for subscription: ' + table
    )
  }
  const identical = (a: Row<T>, b: Row<T>) => {
    return !spec.pk.some((col) => a[col] !== b[col])
  }

  switch (change.eventType) {
    case 'INSERT': {
      const existing = rows.find((r) => identical(change.new, r))
      if (existing != null) {
        return rows
      }
      return [...rows, change.new]
    }
    case 'UPDATE': {
      const idx = rows.findIndex((r) => identical(change.new, r))
      if (idx == -1) {
        return [...rows, change.new]
      }
      if (!spec.ts || spec.ts(rows[idx]) < spec.ts(change.new)) {
        return [...rows.slice(0, idx), change.new, ...rows.slice(idx + 1)]
      } else {
        return rows
      }
    }
    case 'DELETE': {
      const idx = rows.findIndex((r) => identical(change.old as Row<T>, r))
      if (idx == -1) {
        return rows
      }
      return [...rows.slice(0, idx), ...rows.slice(idx + 1)]
    }
    default: {
      console.warn('Unknown change type, ignoring: ', change)
      return rows
    }
  }
}
