import React from 'react'
import ReactDOM from 'react-dom/client'
import './theme/styles/index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from '@/state'
import SupabaseUpdater from '@/components/Supabase/SupabaseUpdater'

const useStrictMode = process.env?.REACT_APP_USE_STRICT_MODE ?? 'true'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function Updaters() {
  return (
    <>
      <SupabaseUpdater />
    </>
  )
}

root.render(
  useStrictMode.toLowerCase() === 'true' ? (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Updaters />
          <App />
        </BrowserRouter>
      </ReduxProvider>
    </React.StrictMode>
  ) : (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Updaters />
        <App />
      </BrowserRouter>
    </ReduxProvider>
  )
)

reportWebVitals()
