import { createSlice } from '@reduxjs/toolkit'

export type TApplicationToastState = {
  open: boolean
  type: EApplicationToast
  message: string
}

export enum EApplicationToast {
  SUCCESS,
  FAIL
}
export interface IApplicationState {
  readonly isApplicationLoading: boolean
  readonly toastApplication: TApplicationToastState | null
}

const initialState: IApplicationState = {
  isApplicationLoading: false,
  toastApplication: null
}

const applictaionSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationLoading(state, action) {
      state.isApplicationLoading = action.payload
    },
    setToast(state, action) {
      state.toastApplication = action.payload
    }
  }
})

export const { setApplicationLoading, setToast } = applictaionSlice.actions
export default applictaionSlice.reducer
