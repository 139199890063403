import Layout from '@/Layouts/Default'
import { Container } from '@/components/UI/Container'
import React, { useCallback, useState } from 'react'
import { OrderHistoryTable } from './components/OrderHistoryTable'
import { useOrderBookHistory } from '@/hooks/useOrderBookHistory'
import { Button, DatePicker } from 'antd'
import type { TablePaginationConfig } from 'antd/es/table'
import moment, { Moment } from 'moment'
import { ExportOutlined } from '@ant-design/icons'
import { exportToCsv } from '@/utils'
import type { Dayjs } from 'dayjs'
import { getOrderBookHistoryExportData } from '@/supabase/Orderbook/orderbook'
import { useToastApplication } from '@/state/application/hooks'
const { RangePicker } = DatePicker

export const OrderHistory = () => {
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [dateRange, setDateRange] = useState<{
    startDate: Moment
    endDate: Moment
  } | null>(null)
  const { fail } = useToastApplication()
  const { data: orderBookHistory } = useOrderBookHistory({
    pageNumber: currentPage,
    itemsPerPage: perPage,
    ...(dateRange && { filters: dateRange })
  })
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1)
    setPerPage(pagination.pageSize || 10)
  }

  const handleExport = useCallback(async () => {
    if (!dateRange) {
      fail({ message: 'Please select date range before export data.' })
      return
    }
    try {
      const orderBooks = await getOrderBookHistoryExportData({
        ...dateRange
      })

      const orderBookHistoryFormat = orderBooks.map((order) => {
        return {
          'Order ID': order.id,
          'License Plate': order.vehicle_license_plate_id,
          ใหญ่: order.large_ice_cube_amount,
          เล็ก: order.small_ice_cube_amount,
          โม่: order.crushed_ice_amount,
          มย: order.coarsely_crushed_ice_amount,
          Monitor: order.monitor_number,
          Status: order.status,
          'Created At': order.created_at,
          'Updated At': order.updated_at,
          'Monitory Display At': order.monitor_display_at,
          'Completed At': order.completed_at,
          'Deleted At': order.deleted_at
        }
      })
      const fileName = `SK_Unic_Order_History_${moment().format(
        'YYYY-MM-DDTHH-mm-ss'
      )}`
      exportToCsv(orderBookHistoryFormat, fileName)
    } catch (error) {
      fail({ message: 'An error occurred while exporting data.' })
    }
  }, [orderBookHistory])

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (!dates) {
      setDateRange(null)
      return
    }

    setDateRange({
      startDate: moment(dates[0]?.toISOString())?.startOf('days'),
      endDate: moment(dates[1]?.toISOString())?.endOf('days')
    })
  }

  return (
    <Layout>
      <Container maxWidth="7xl">
        <div className="flex flex-col">
          <div className="flex py-5 justify-between items-center">
            <p className="font-semibold text-[30px]">Order History</p>
            <div className="flex gap-5">
              <RangePicker
                size="large"
                format={'DD/MM/YYYY'}
                // @ts-ignore
                onChange={onRangeChange}
              />
              <Button
                icon={<ExportOutlined />}
                size="large"
                onClick={handleExport}
              >
                Export Data
              </Button>
            </div>
          </div>
          <OrderHistoryTable
            orderHistories={orderBookHistory?.data}
            currentPage={currentPage}
            itemsPerPage={perPage}
            totalItems={orderBookHistory?.totalItems ?? perPage}
            handleTableChange={handleTableChange}
          />
        </div>
      </Container>
    </Layout>
  )
}
