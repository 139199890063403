import React, { FC } from 'react'
import { ConfigProvider as AntdConfigProvider } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import { defaulTheme } from './AntdThemeConfig'
interface IAntdThemeProviderProps {
  children: React.ReactNode
}

export const AntdThemeProvider: FC<IAntdThemeProviderProps> = ({
  children
}) => {
  return (
    <StyleProvider hashPriority="high">
      <AntdConfigProvider theme={defaulTheme}>{children}</AntdConfigProvider>
    </StyleProvider>
  )
}
