import React, { useMemo } from 'react'
import { useSubscription } from '@/libs/supabase/hooks/useSubscription'
import { filter, orderBy } from 'lodash'
import { getOrderBookStatusOpen } from '@/supabase/Orderbook/orderbook'

export const useOrderBookStatusOpen = () => {
  const { rows } = useSubscription('OrderBook', undefined, () =>
    getOrderBookStatusOpen()
  )
  return useMemo(
    () => orderBy(filter(rows, { status: 'OPEN' }), ['created_at'], ['desc']),
    [rows]
  )
}
