import { useToastApplication } from '@/state/application/hooks'
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport
} from '.'
import { ToastSuccessIcon } from '@/components/Icons/ToastSuccessIcon'
import { EApplicationToast } from '@/state/application/reducer'
import { classNames } from '@/utils'
import { useCallback, useEffect, useState } from 'react'
import { ToastFailIcon } from '@/components/Icons/ToastFailIcon'

export const ToastApplication = () => {
  const [toastOpen, setToastOpen] = useState<boolean>(false)
  const { isToast, toastClose } = useToastApplication()

  useEffect(() => {
    setToastOpen(isToast?.open ?? false)
  }, [isToast])

  const handleCloseToast = useCallback(() => {
    setToastOpen(false)
    setTimeout(() => {
      toastClose()
    }, 300)
  }, [])

  return (
    <ToastProvider duration={3000} swipeDirection="up">
      <Toast
        className="!w-[350px] p-0 border-0"
        open={toastOpen}
        onOpenChange={handleCloseToast}
        type="background"
      >
        <div className="grid grid-cols-[auto_1fr] gap-2 h-[100%]">
          <div
            className={classNames(
              'h-[100%] flex items-center justify-center px-4',
              isToast?.type === EApplicationToast.FAIL && 'bg-error',
              isToast?.type === EApplicationToast.SUCCESS && 'bg-success'
            )}
          >
            {isToast?.type === EApplicationToast.SUCCESS && (
              <ToastSuccessIcon />
            )}
            {isToast?.type === EApplicationToast.FAIL && <ToastFailIcon />}
          </div>
          <div className="grid gap-1 p-4">
            <ToastTitle className="text-md">
              {isToast?.type === EApplicationToast.SUCCESS && 'Success'}
              {isToast?.type === EApplicationToast.FAIL && 'Error'}
            </ToastTitle>
            <ToastDescription>{isToast?.message}</ToastDescription>
          </div>
        </div>
        <ToastClose />
      </Toast>
      <ToastViewport />
    </ToastProvider>
  )
}
