import {
  PostgrestResponse,
  PostgrestSingleResponse,
  RealtimePostgresInsertPayload,
  RealtimePostgresUpdatePayload,
  RealtimePostgresDeletePayload,
  REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
  REALTIME_SUBSCRIBE_STATES
} from '@supabase/supabase-js'
import { Database } from '@/configs/supabase/schema'

export type Schema = Database['public']
export type Tables = Schema['Tables']
export type Views = Schema['Views']
export type TableName = keyof Tables
export type ViewName = keyof Views
export type Selectable = TableName | ViewName
export type Row<T extends Selectable> = T extends TableName
  ? Tables[T]['Row']
  : T extends ViewName
  ? Views[T]['Row']
  : never
export type Column<T extends Selectable> = keyof Row<T> & string

export type Insert<T extends TableName> = RealtimePostgresInsertPayload<Row<T>>
export type Update<T extends TableName> = RealtimePostgresUpdatePayload<Row<T>>
export type Delete<T extends TableName> = RealtimePostgresDeletePayload<Row<T>>
export type Event = `${REALTIME_POSTGRES_CHANGES_LISTEN_EVENT}`
export type EventChangeTypes<T extends TableName> = {
  '*': Insert<T> | Update<T> | Delete<T>
  INSERT: Insert<T>
  UPDATE: Update<T>
  DELETE: Delete<T>
}
export type Change<
  T extends TableName,
  E extends Event = '*'
> = EventChangeTypes<T>[E]
export type Filter<T extends TableName> = { k: Column<T>; v: string | number }
export type QueryResponse<T> = PostgrestResponse<T> | PostgrestSingleResponse<T>
export type QueryMultiSuccessResponse<T> = { data: T[]; count: number }
export type QuerySingleSuccessResponse<T> = { data: T; count: number }

export async function run<T>(
  q: PromiseLike<PostgrestResponse<T>>
): Promise<QueryMultiSuccessResponse<T>>
export async function run<T>(
  q: PromiseLike<PostgrestSingleResponse<T>>
): Promise<QuerySingleSuccessResponse<T>>
export async function run<T>(
  q: PromiseLike<PostgrestSingleResponse<T> | PostgrestResponse<T>>
) {
  const { data, count, error } = await q
  if (error != null) {
    throw error
  } else {
    return { data, count }
  }
}

export type SubscriptionStatus = `${REALTIME_SUBSCRIBE_STATES}`
export type TSubscriptionLifeCycleStatus =
  | 'unknown'
  | 'starting'
  | 'fetching'
  | 'live'
  | 'errored'
  | 'disabled'
export type TableSpec<T extends TableName> = {
  pk: Column<T>[]
  ts?: (row: Row<T>) => number
}

export const REALTIME_TABLES: Partial<{ [T in TableName]: TableSpec<T> }> = {
  OrderBook: {
    pk: ['id']
  },
  VehicleLicensePlate: {
    pk: ['id'],
    ts: (r) => Date.parse(r.updated_at)
  }
}
