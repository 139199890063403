import { IAntdThemeConfig } from './interfaces'

export const defaulTheme: IAntdThemeConfig = {
  components: {
    Menu: {
      horizontalLineHeight: '70px',
      fontSize: '18px',
      iconMarginInlineEnd: 12
    }
  }
}
