import React, { useMemo } from 'react'
import { useSubscription } from '@/libs/supabase/hooks/useSubscription'
import { supabaseClient } from '@/configs/supabase'
import { getVehicleLicensePlateId } from '@/supabase/VehicleLicensePlat'

export const useVehicleLicensePlateId = () => {
  const { rows } = useSubscription('VehicleLicensePlate', undefined, () =>
    getVehicleLicensePlateId(supabaseClient)
  )

  return rows?.map((row) => row.id)
}
