import { configureStore } from '@reduxjs/toolkit'
import reducer from './reducer'
import thunk from 'redux-thunk'

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunk),
  devTools: process.env.NODE_ENV === 'development'
})

export type AppState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch

export default store
