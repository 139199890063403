import { OrderForm } from '@/components/OrderForm'
import { TOrderForm } from '@/components/OrderForm/OrderForm.interface'
import { Card, CardTitle } from '@/components/UI/Container/Card'
import { useOrderBookStatusOpen } from '@/hooks/useOrderBookStatusOpen'
import { AppState } from '@/state'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const initForm: TOrderForm = {
  vehicleLicensePlateId: '',
  largeIceCubeAmount: null,
  smallIceCubeAmount: null,
  crushedIceAmount: null,
  coarselyCrushedIceAmount: null,
  monitorNumber: 1
}

export const LeftSection: FC = () => {
  const orderFormStatus = useSelector(
    (appState: AppState) => appState.orderManager.orderFormStatus
  )

  const orderIdForEdit = useSelector(
    (appState: AppState) => appState.orderManager.orderIdForEdit
  )
  const orderbooksStatusOpen = useOrderBookStatusOpen()

  const [currentFormData, setCurrentFormData] = useState<TOrderForm>(initForm)

  useEffect(() => {
    if (orderFormStatus === 'CREATE') {
      setCurrentFormData(initForm)
    } else {
      const orderSelected = orderbooksStatusOpen.find(
        (order) => order.id === orderIdForEdit
      )
      if (orderSelected) {
        setCurrentFormData({
          vehicleLicensePlateId: orderSelected.vehicle_license_plate_id,
          largeIceCubeAmount: orderSelected.large_ice_cube_amount,
          smallIceCubeAmount: orderSelected.small_ice_cube_amount,
          crushedIceAmount: orderSelected.crushed_ice_amount,
          coarselyCrushedIceAmount: orderSelected.coarsely_crushed_ice_amount,
          monitorNumber: orderSelected.monitor_number
        })
      }
    }
  }, [orderFormStatus, orderIdForEdit])
  return (
    <Card className="w-full h-full py-8 px-11">
      <CardTitle className="mb-10">
        {orderFormStatus === 'CREATE' ? 'Create Order' : 'Edit Order'}
      </CardTitle>
      <OrderForm
        orderFormStatus={orderFormStatus}
        formData={currentFormData}
        orderIdForEdit={orderIdForEdit}
      />
    </Card>
  )
}
