import { Container } from '@/components/UI/Container'
import React, { FC } from 'react'

interface ILayoutProps {
  children?: React.ReactNode
}

const MonitorLayout: FC<ILayoutProps> = (props: ILayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen relative bg-backGroundBase dark:bg-backGroundBase">
      <Container maxWidth="full">{props.children}</Container>
    </div>
  )
}

export default MonitorLayout
