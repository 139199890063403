import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EApplicationToast,
  TApplicationToastState,
  setApplicationLoading,
  setToast
} from './reducer'
import { AppState } from '@/state'

export const useLoading = () => {
  const dispatch = useDispatch()
  const isApplicationLoading = useSelector(
    (state: AppState) => state.application.isApplicationLoading
  )

  const isLoading = useCallback(() => {
    dispatch(setApplicationLoading(true))
  }, [dispatch])

  const unLoading = useCallback(() => {
    dispatch(setApplicationLoading(false))
  }, [dispatch])

  return {
    isApplicationLoading,
    isLoading,
    unLoading
  }
}

export const useToastApplication = () => {
  const dispatch = useDispatch()
  const isToast = useSelector(
    (state: AppState) => state.application.toastApplication
  )

  const success = useCallback(
    ({ message }: Pick<TApplicationToastState, 'message'>) => {
      const isSuccess: TApplicationToastState = {
        open: true,
        type: EApplicationToast.SUCCESS,
        message: message
      }
      dispatch(setToast(isSuccess))
    },
    [dispatch]
  )

  const fail = useCallback(
    ({ message }: Pick<TApplicationToastState, 'message'>) => {
      const isFail: TApplicationToastState = {
        open: true,
        type: EApplicationToast.FAIL,
        message: message
      }
      dispatch(setToast(isFail))
    },
    [dispatch]
  )

  const toastClose = useCallback(() => {
    dispatch(setToast(null))
  }, [dispatch])

  return {
    isToast,
    success,
    fail,
    toastClose
  }
}
