import * as Yup from 'yup'

export const OrderFormSchema = Yup.object()
  .shape({
    vehicleLicensePlateId: Yup.string().required(
      'Vehicle License Plate is required'
    ),
    largeIceCubeAmount: Yup.number()
      .nullable()
      .min(0, 'Amount must be at least 0')
      .max(10000, 'Amount must not exceed 10000'),
    smallIceCubeAmount: Yup.number()
      .nullable()
      .min(0, 'Amount must be at least 0')
      .max(10000, 'Amount must not exceed 10000'),
    crushedIceAmount: Yup.number()
      .nullable()
      .min(0, 'Amount must be at least 0')
      .max(10000, 'Amount must not exceed 10000'),
    coarselyCrushedIceAmount: Yup.number()
      .nullable()
      .min(0, 'Amount must be at least 0')
      .max(10000, 'Amount must not exceed 10000'),
    monitorNumber: Yup.number()
      .min(1, 'Monitor number must be at least 1')
      .required('Monitor number is required')
  })
  .test(
    'at-least-one-ice',
    'You must order more than 0 of at least one type of ice',
    function (value) {
      const {
        largeIceCubeAmount,
        smallIceCubeAmount,
        crushedIceAmount,
        coarselyCrushedIceAmount
      } = value
      const totalIce =
        (largeIceCubeAmount || 0) +
        (smallIceCubeAmount || 0) +
        (crushedIceAmount || 0) +
        (coarselyCrushedIceAmount || 0)
      return totalIce > 0
    }
  )

export type TOrderForm = Yup.InferType<typeof OrderFormSchema>
