import { supabaseClient } from '@/configs/supabase'
import { Row } from '@/libs/supabase/supabase.interface'
import { TOrderForm } from '@/components/OrderForm/OrderForm.interface'
import { IGetOrderBookHistoryFilter } from './orderbook.interface'

const getCurrentTimestamp = () => new Date()

const handleError = (error: any, message: string) => {
  console.error(message, error)
  return null
}

const checkOrCreateVehicleLicensePlate = async (id: string) => {
  const { data: existingPlate, error: plateError } = await supabaseClient
    .from('VehicleLicensePlate')
    .select('id')
    .eq('id', id)

  if (plateError)
    return handleError(
      plateError,
      'Error checking for existing VehicleLicensePlate:'
    )

  if (existingPlate.length === 0) {
    const timestamp = getCurrentTimestamp()
    const { error: newPlateError } = await supabaseClient
      .from('VehicleLicensePlate')
      .insert({ id, created_at: timestamp, updated_at: timestamp })
      .single()

    if (newPlateError)
      return handleError(
        newPlateError,
        'Error creating new VehicleLicensePlate:'
      )
  }

  return true
}

export const createOrder = async (newOrder: TOrderForm) => {
  if (
    !(await checkOrCreateVehicleLicensePlate(newOrder.vehicleLicensePlateId))
  ) {
    return
  }

  // Now create the OrderBook linked to the VehicleLicensePlate
  const createdAt = getCurrentTimestamp()
  const { data: newOrderBook, error: orderBookError } = await supabaseClient
    .from('OrderBook')
    .insert({
      ...(newOrder.largeIceCubeAmount && {
        large_ice_cube_amount: newOrder.largeIceCubeAmount
      }),
      ...(newOrder.smallIceCubeAmount && {
        small_ice_cube_amount: newOrder.smallIceCubeAmount
      }),
      ...(newOrder.crushedIceAmount && {
        crushed_ice_amount: newOrder.crushedIceAmount
      }),
      ...(newOrder.coarselyCrushedIceAmount && {
        coarsely_crushed_ice_amount: newOrder.coarselyCrushedIceAmount
      }),
      monitor_number: newOrder.monitorNumber,
      vehicle_license_plate_id: newOrder.vehicleLicensePlateId, // Link the OrderBook to the VehicleLicensePlate
      is_monitor_display: false,
      status: 'OPEN',
      created_at: createdAt,
      updated_at: createdAt
    })

  if (orderBookError) {
    return handleError(orderBookError, 'Error creating OrderBook:')
  }

  return newOrderBook
}

export async function updateOrder(
  orderBookId: number,
  updatedOrderData: TOrderForm
) {
  if (
    !(await checkOrCreateVehicleLicensePlate(
      updatedOrderData.vehicleLicensePlateId
    ))
  ) {
    return
  }

  const updatedAt = getCurrentTimestamp()
  const { data: updatedOrderBook, error: orderBookError } = await supabaseClient
    .from('OrderBook')
    .update({
      ...(updatedOrderData.largeIceCubeAmount && {
        large_ice_cube_amount: updatedOrderData.largeIceCubeAmount
      }),
      ...(updatedOrderData.smallIceCubeAmount && {
        small_ice_cube_amount: updatedOrderData.smallIceCubeAmount
      }),
      ...(updatedOrderData.crushedIceAmount && {
        crushed_ice_amount: updatedOrderData.crushedIceAmount
      }),
      ...(updatedOrderData.coarselyCrushedIceAmount && {
        coarsely_crushed_ice_amount: updatedOrderData.coarselyCrushedIceAmount
      }),
      monitor_number: updatedOrderData.monitorNumber,
      is_monitor_display: false,
      vehicle_license_plate_id: updatedOrderData.vehicleLicensePlateId,
      updated_at: updatedAt
    })
    .eq('id', orderBookId)

  if (orderBookError) {
    return handleError(orderBookError, 'Error updating OrderBook:')
  }

  return updatedOrderBook
}

export async function updateOrderComplete(orderBookId: number) {
  const completedAt = new Date()
  const { data: orderBookCompleted, error: orderBookError } =
    await supabaseClient
      .from('OrderBook')
      .update({
        status: 'COMPLETED',
        completed_at: completedAt,
        is_monitor_display: false
      })
      .eq('id', orderBookId)

  if (orderBookError) {
    console.error('Error update order:', orderBookError)
    return
  }

  return orderBookCompleted
}

export async function switchOrderMonitorDisplay(
  orderId: number,
  monitorNo: number,
  isMonitorDisplay: boolean
) {
  const updatedAt = new Date()
  const swapMonitorDisplayStatus = !isMonitorDisplay

  const { data: deletedAtOrderBook, error: transactionError } =
    await supabaseClient
      .from('OrderBook')
      .update({
        is_monitor_display: false,
        updated_at: updatedAt
      })
      .eq('is_monitor_display', true)
      .eq('monitor_number', monitorNo)
      .then(() =>
        supabaseClient
          .from('OrderBook')
          .update({
            is_monitor_display: swapMonitorDisplayStatus,
            updated_at: updatedAt,
            // Update when user click monitor display order
            ...(swapMonitorDisplayStatus && {
              monitor_display_at: updatedAt
            })
          })
          .eq('id', orderId)
      )

  if (transactionError) {
    return handleError(transactionError, 'Error switch order monitor display')
  }

  return deletedAtOrderBook
}

export async function softDeleteOrder(orderId: number) {
  const deletedAt = new Date()
  const { data: deletedAtOrderBook, error: orderBookError } =
    await supabaseClient
      .from('OrderBook')
      .update({
        status: 'DELETED',
        updated_at: deletedAt,
        deleted_at: deletedAt,
        is_monitor_display: false
      })
      .eq('id', orderId)

  if (orderBookError) {
    return handleError(orderBookError, 'Error deleting OrderBook:')
  }

  return deletedAtOrderBook
}

export async function getOrderBookStatusOpen() {
  const { data } = await supabaseClient
    .from('OrderBook')
    .select('*')
    .eq('status', 'OPEN')
    .order('created_at', { ascending: false } as any)

  return data?.map((d: Row<'OrderBook'>) => d)
}

export async function findOrderBookFromMonitorNo(monitorNo: number) {
  const { data, error } = await supabaseClient
    .from('OrderBook')
    .select('*')
    .eq('monitor_number', monitorNo)
    .eq('status', 'OPEN')
    .is('is_monitor_display', true)
    .order('created_at', { ascending: false } as any)
    .limit(1)

  if (error) {
    console.error('Error fetching data', error)
    return []
  }

  return data
}

export async function getOrderBookHistory(
  pageNumber: number,
  itemsPerPage: number,
  filters?: IGetOrderBookHistoryFilter
) {
  let query = supabaseClient
    .from('OrderBook')
    .select('*', { count: 'exact' })
    .order('created_at', { ascending: false } as any)
    .range((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage - 1)

  if (filters?.startDate && filters?.endDate) {
    query = query
      .gte('created_at', filters.startDate.toISOString())
      .lte('created_at', filters.endDate.toISOString())
  }

  const { data, count } = await query

  const totalItems = count ?? 0
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  return {
    data: data?.map((d: Row<'OrderBook'>) => d) || [],
    totalPages,
    totalItems: count
  }
}

export async function getOrderBookHistoryExportData(
  filters?: IGetOrderBookHistoryFilter
) {
  let query = supabaseClient
    .from('OrderBook')
    .select('*')
    .order('created_at', { ascending: false } as any)

  if (filters?.startDate && filters?.endDate) {
    query = query
      .gte('created_at', filters.startDate.toISOString())
      .lte('created_at', filters.endDate.toISOString())
  }

  const { data } = await query

  return data?.map((d: Row<'OrderBook'>) => d) ?? []
}
