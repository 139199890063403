import { Input, Radio, Select, AutoComplete, AutoCompleteProps } from 'antd'
import React, { forwardRef } from 'react'
import { InputProps } from 'antd/lib/input'
import { SelectProps } from 'antd/lib/select'
import { RadioGroupProps } from 'antd/lib/radio'
import { ErrorMessage, useField } from 'formik'
import classNames from 'classnames'
import { cn } from '@/utils'

interface AntdInputValidateFieldtProps extends InputProps {
  name: string
}
export const AntdInputValidateField = forwardRef<
  React.ElementRef<typeof Input>,
  AntdInputValidateFieldtProps
>(({ name, ...restProps }, ref) => {
  const [field, meta] = useField(name)
  return (
    <div>
      <Input
        ref={ref}
        name={name}
        {...restProps}
        status={meta.touched && meta.error ? 'error' : undefined}
        className={cn('px-3 py-3 rounded-[4px]', restProps?.className)}
      />

      <ErrorMessage name={name}>
        {(msg) => (
          <div
            className={classNames(
              msg ? 'visible' : 'invisible',
              'text-red-500 text-xs italic mt-2'
            )}
          >
            {msg}
          </div>
        )}
      </ErrorMessage>
    </div>
  )
})

interface AntdRadioGroupComponentProps extends RadioGroupProps {
  name: string
  options: { value: any; label: string }[]
}

export const AntdRadioGroupComponent = forwardRef<
  React.ElementRef<typeof Radio.Group>,
  AntdRadioGroupComponentProps
>(({ name, options, ...restProps }, ref) => {
  return (
    <div>
      <Radio.Group
        name={name}
        ref={ref}
        {...restProps}
        buttonStyle="outline"
        aria-labelledby={options.join('-')}
        className="!flex !grow"
      >
        {options.map((option, idx) => (
          <Radio.Button
            value={option.value}
            key={idx}
            className="w-full text-center"
          >
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>

      <ErrorMessage name={name}>
        {(msg) => (
          <div
            className={classNames(
              msg ? 'visible' : 'invisible',
              'text-red-500 text-xs italic mt-2'
            )}
          >
            {msg}
          </div>
        )}
      </ErrorMessage>
    </div>
  )
})

interface AntdAutoCompleteValidateFieldProps extends AutoCompleteProps {
  name: string
}
export const AntdAutoCompleteValidateField = forwardRef<
  React.ElementRef<typeof AutoComplete>,
  AntdAutoCompleteValidateFieldProps
>(({ name, ...restProps }, ref) => {
  const [field, meta] = useField(name)

  return (
    <div>
      <AutoComplete
        ref={ref}
        {...restProps}
        status={meta.touched && meta.error ? 'error' : undefined}
      />

      <ErrorMessage name={name}>
        {(msg) => (
          <div
            className={classNames(
              msg ? 'visible' : 'invisible',
              'text-red-500 text-xs italic mt-2'
            )}
          >
            {msg}
          </div>
        )}
      </ErrorMessage>
    </div>
  )
})
