import { FC } from 'react'
type ToastFailIconProps = React.ComponentProps<'svg'> & {
  color?: string
}

export const ToastFailIcon: FC<ToastFailIconProps> = ({ ...svgProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <mask id="path-5-inside-1_0_1" fill="white">
        <path d="M16.5 30.25C24.0939 30.25 30.25 24.0939 30.25 16.5C30.25 8.90608 24.0939 2.75 16.5 2.75C8.90608 2.75 2.75 8.90608 2.75 16.5C2.75 24.0939 8.90608 30.25 16.5 30.25Z" />
      </mask>
      <path
        d="M21.3321 13.0821C21.7226 12.6916 21.7226 12.0584 21.3321 11.6679C20.9416 11.2774 20.3084 11.2774 19.9179 11.6679L21.3321 13.0821ZM11.6679 19.9179C11.2774 20.3084 11.2774 20.9416 11.6679 21.3321C12.0584 21.7226 12.6916 21.7226 13.0821 21.3321L11.6679 19.9179ZM13.0821 11.6679C12.6916 11.2774 12.0584 11.2774 11.6679 11.6679C11.2774 12.0584 11.2774 12.6916 11.6679 13.0821L13.0821 11.6679ZM19.9179 21.3321C20.3084 21.7226 20.9416 21.7226 21.3321 21.3321C21.7226 20.9416 21.7226 20.3084 21.3321 19.9179L19.9179 21.3321ZM19.9179 11.6679L11.6679 19.9179L13.0821 21.3321L21.3321 13.0821L19.9179 11.6679ZM11.6679 13.0821L19.9179 21.3321L21.3321 19.9179L13.0821 11.6679L11.6679 13.0821ZM28.25 16.5C28.25 22.9893 22.9893 28.25 16.5 28.25V32.25C25.1985 32.25 32.25 25.1985 32.25 16.5H28.25ZM16.5 28.25C10.0107 28.25 4.75 22.9893 4.75 16.5H0.75C0.75 25.1985 7.80152 32.25 16.5 32.25V28.25ZM4.75 16.5C4.75 10.0107 10.0107 4.75 16.5 4.75V0.75C7.80152 0.75 0.75 7.80152 0.75 16.5H4.75ZM16.5 4.75C22.9893 4.75 28.25 10.0107 28.25 16.5H32.25C32.25 7.80152 25.1985 0.75 16.5 0.75V4.75Z"
        fill="white"
        mask="url(#path-5-inside-1_0_1)"
      />
    </svg>
  )
}
