import React from 'react'
import { Router } from './routes'
import { AntdThemeProvider } from './theme/components/AntdThemeProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()

function App() {
  return (
    <div className="App h-screen w-full">
      <AntdThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </AntdThemeProvider>
    </div>
  )
}

export default App
