import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TOrderFormStatus = 'CREATE' | 'UPDATE'

export interface IOrderManagerState {
  readonly orderIdForEdit: number | null
  readonly orderFormStatus: TOrderFormStatus
}

const initialState: IOrderManagerState = {
  orderIdForEdit: null,
  orderFormStatus: 'CREATE'
}

const orderManagerSlice = createSlice({
  name: 'orderManager',
  initialState,
  reducers: {
    setOrderIdForEdit(state, action: PayloadAction<number | null>) {
      state.orderIdForEdit = action.payload
    },
    setOrderFormStatus(state, action: PayloadAction<TOrderFormStatus>) {
      state.orderFormStatus = action.payload
    }
  }
})

export const { setOrderFormStatus, setOrderIdForEdit } =
  orderManagerSlice.actions
export default orderManagerSlice.reducer
