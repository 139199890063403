import React, { FC, useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Formik, Form, FormikHelpers } from 'formik'
import { OrderFormSchema, TOrderForm } from './OrderForm.interface'
import { Button, Popconfirm } from 'antd'
import {
  AntdAutoCompleteValidateField,
  AntdInputValidateField,
  AntdRadioGroupComponent
} from '../FormikForm/AntdValidateField'
import {
  createOrder,
  updateOrder,
  softDeleteOrder
} from '@/supabase/Orderbook/orderbook'
import { useVehicleLicensePlateId } from '@/hooks/useVehicleLicensePlate'
import {
  TOrderFormStatus,
  setOrderFormStatus,
  setOrderIdForEdit
} from '@/state/orderManager/reducer'
import { useAppDispatch } from '@/state/hooks'
import { batch } from 'react-redux'
import { useToastApplication } from '@/state/application/hooks'
import { updateEmptyStringsToNull } from '@/utils'

const validateForm = async (formData: TOrderForm) => {
  try {
    await OrderFormSchema.validate(formData, { abortEarly: false })
    return { isValid: true, errors: [] }
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      return {
        isValid: false,
        errors: err.inner.map((error) => ({
          path: error.path,
          message: error.message
        }))
      }
    }
    return {
      isValid: false,
      errors: [{ path: null, message: 'Validation failed' }]
    }
  }
}

export interface IOrderFormProps {
  orderFormStatus: TOrderFormStatus
  formData: TOrderForm
  orderIdForEdit?: number | null
}

export const OrderForm: FC<IOrderFormProps> = ({
  orderFormStatus,
  formData,
  orderIdForEdit
}) => {
  const { fail } = useToastApplication()
  const vehicleLicensePlateIds = useVehicleLicensePlateId()
  const vehicleLicensePlateIdOptions = useMemo(
    () =>
      vehicleLicensePlateIds?.map((id) => ({
        value: id,
        label: id
      })) ?? [],
    [vehicleLicensePlateIds]
  )
  const dispatch = useAppDispatch()

  const handleBtnClickCancel = useCallback(() => {
    batch(() => {
      dispatch(setOrderFormStatus('CREATE'))
      dispatch(setOrderIdForEdit(null))
    })
  }, [dispatch])

  const handleBtnClickDelete = useCallback(async () => {
    if (!orderIdForEdit) {
      throw new Error(`Not found orderId for delete order`)
    }
    await softDeleteOrder(orderIdForEdit)

    batch(() => {
      dispatch(setOrderFormStatus('CREATE'))
      dispatch(setOrderIdForEdit(null))
    })
  }, [dispatch, orderIdForEdit])

  const handleSubmitForm = useCallback(
    async (formsData: TOrderForm, { resetForm }: FormikHelpers<TOrderForm>) => {
      try {
        const validateTestCase = await validateForm(
          updateEmptyStringsToNull(formsData)
        )
        if (!validateTestCase.isValid) {
          throw new Error(validateTestCase.errors[0]?.message)
        }

        if (orderFormStatus === 'CREATE') {
          await createOrder(formsData)
        } else if (orderFormStatus === 'UPDATE') {
          if (!orderIdForEdit) {
            throw new Error('Not found order id for updating order.')
          }

          await updateOrder(orderIdForEdit, formsData)
          batch(() => {
            dispatch(setOrderFormStatus('CREATE'))
            dispatch(setOrderIdForEdit(null))
          })
        }

        resetForm()
      } catch (error: any) {
        fail({ message: error?.message })
      }
    },
    [orderFormStatus, orderFormStatus, orderIdForEdit]
  )

  return (
    <Formik
      initialValues={formData}
      validationSchema={OrderFormSchema}
      enableReinitialize
      onSubmit={handleSubmitForm}
    >
      {({ values, handleChange, handleBlur, setFieldValue }) => {
        const onSelectvehicleLicensePlateId = useCallback(
          (vehicleLicensePlateId: string) => {
            setFieldValue('vehicleLicensePlateId', vehicleLicensePlateId)
          },
          [setFieldValue]
        )

        return (
          <Form noValidate className="flex flex-col gap-5 w-full">
            <div className="flex items-center gap-3">
              <div className="font-base w-2/6 text-right">License Plate</div>
              <div className="grow">
                <AntdAutoCompleteValidateField
                  name="vehicleLicensePlateId"
                  placeholder="Select License Plate Id"
                  showSearch
                  className="w-full h-[48px] rounded-[4px]"
                  value={values.vehicleLicensePlateId}
                  options={vehicleLicensePlateIdOptions}
                  onChange={onSelectvehicleLicensePlateId}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? '').includes(
                      input?.toLowerCase()
                    )
                  }
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="font-base w-2/6 text-right">ใหญ่</div>
              <div className="grow">
                <AntdInputValidateField
                  name="largeIceCubeAmount"
                  type="number"
                  className="text-filed-arrow-hide arrow-hide"
                  placeholder="ใหญ่"
                  value={values.largeIceCubeAmount ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="font-base w-2/6 text-right">เล็ก</div>
              <div className="grow">
                <AntdInputValidateField
                  name="smallIceCubeAmount"
                  type="number"
                  className="text-filed-arrow-hide arrow-hide"
                  placeholder="เล็ก"
                  value={values.smallIceCubeAmount ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="font-base w-2/6 text-right">โม่</div>
              <div className="grow">
                <AntdInputValidateField
                  name="crushedIceAmount"
                  type="number"
                  className="text-filed-arrow-hide arrow-hide"
                  placeholder="โม่"
                  value={values.crushedIceAmount ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="font-base w-2/6 text-right">โม่หยาบ</div>
              <div className="grow">
                <AntdInputValidateField
                  name="coarselyCrushedIceAmount"
                  type="number"
                  className="text-filed-arrow-hide arrow-hide"
                  placeholder="โม่หยาบ"
                  value={values.coarselyCrushedIceAmount ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="font-base w-2/6 text-right">Monitor</div>
              <div className="grow">
                <AntdRadioGroupComponent
                  name="monitorNumber"
                  size="large"
                  onChange={handleChange}
                  options={[
                    { label: 'Monitor 1', value: 1 },
                    { label: 'Monitor 2', value: 2 }
                  ]}
                  optionType="button"
                  value={values.monitorNumber}
                />
              </div>
            </div>

            {orderFormStatus === 'CREATE' && (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="group relative w-full !rounded-md self-center mt-5"
              >
                Create Order
              </Button>
            )}
            {orderFormStatus === 'UPDATE' && (
              <div className="flex gap-3 mt-5 items-center">
                <Popconfirm
                  title="Delete order"
                  description="Are you sure to delete this order?"
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    style: { fontSize: '16px' }
                  }}
                  cancelButtonProps={{
                    style: { fontSize: '16px' }
                  }}
                  onConfirm={handleBtnClickDelete}
                >
                  <Button type="primary" className="w-1/6" danger>
                    Delete
                  </Button>
                </Popconfirm>
                <Button
                  type="default"
                  className="w-1/6"
                  onClick={handleBtnClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="w-full"
                >
                  Edit Order
                </Button>
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
