import Layout from '@/Layouts/Default'
import React from 'react'
import { LeftSection } from './components/LeftSection'
import { RightSection } from './components/RightSection'

export default function index() {
  return (
    <Layout>
      <div className="flex w-full h-full p-5">
        <div className="flex w-2/6 overflow-y-auto">
          <LeftSection />
        </div>
        <div className="flex w-4/6">
          <RightSection />
        </div>
      </div>
    </Layout>
  )
}
