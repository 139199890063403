import React, { FC } from 'react'
import { Container } from '@/components/UI/Container'
import styled from 'styled-components'
import { APP_HEADER_HEIGHT } from '@/constants/envs/constants'

interface IMainProps {
  children?: React.ReactNode
}
const MainContainser = styled(Container)`
  height: calc(100vh - ${APP_HEADER_HEIGHT}px);
`

const Main: FC<IMainProps> = ({ children }) => {
  return <MainContainser maxWidth="full">{children}</MainContainser>
}

export default Main
