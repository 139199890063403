import React from 'react'
import { getOrderBookHistory } from '@/supabase/Orderbook/orderbook'
import { useQuery } from '@tanstack/react-query'
import { IGetOrderBookHistoryFilter } from '@/supabase/Orderbook/orderbook.interface'

interface IuseOrderBookHistoryProps {
  pageNumber: number
  itemsPerPage: number
  filters?: IGetOrderBookHistoryFilter
}
export const useOrderBookHistory = ({
  itemsPerPage,
  pageNumber,
  filters
}: IuseOrderBookHistoryProps) => {
  return useQuery({
    queryKey: ['getOrderBookHistory', { itemsPerPage, pageNumber, filters }],
    queryFn: async () => getOrderBookHistory(pageNumber, itemsPerPage, filters),
    keepPreviousData: false,
    cacheTime: 0,
    retry: 2
  })
}
