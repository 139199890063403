import { FC } from 'react'
type ToastSuccessIconProps = React.ComponentProps<'svg'> & {
  color?: string
}

export const ToastSuccessIcon: FC<ToastSuccessIconProps> = ({
  color = 'white',
  ...svgProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      {...svgProps}
    >
      <g id="tabler:circle-check">
        <g id="Group">
          <path
            id="Vector"
            d="M4.125 16.5C4.125 18.1251 4.44509 19.7343 5.06699 21.2357C5.68889 22.7371 6.60043 24.1013 7.74955 25.2504C8.89868 26.3996 10.2629 27.3111 11.7643 27.933C13.2657 28.5549 14.8749 28.875 16.5 28.875C18.1251 28.875 19.7343 28.5549 21.2357 27.933C22.7371 27.3111 24.1013 26.3996 25.2504 25.2504C26.3996 24.1013 27.3111 22.7371 27.933 21.2357C28.5549 19.7343 28.875 18.1251 28.875 16.5C28.875 14.8749 28.5549 13.2657 27.933 11.7643C27.3111 10.2629 26.3996 8.89868 25.2504 7.74955C24.1013 6.60043 22.7371 5.68889 21.2357 5.06699C19.7343 4.44509 18.1251 4.125 16.5 4.125C14.8749 4.125 13.2657 4.44509 11.7643 5.06699C10.2629 5.68889 8.89868 6.60043 7.74955 7.74955C6.60043 8.89868 5.68889 10.2629 5.06699 11.7643C4.44509 13.2657 4.125 14.8749 4.125 16.5Z"
            stroke="white"
            style={{
              strokeLinejoin: 'round',
              strokeLinecap: 'round',
              strokeWidth: '2'
            }}
          />
          <path
            id="Vector_2"
            d="M12.375 16.5L15.125 19.25L20.625 13.75"
            stroke="white"
            style={{
              strokeLinejoin: 'round',
              strokeLinecap: 'round',
              strokeWidth: '2'
            }}
          />
        </g>
      </g>
    </svg>
  )
}
