import { FC } from 'react'
import { Navigation } from '../Nevigation'
interface IHeaderProps {
  title: string
}

const Header: FC<IHeaderProps> = ({ title }) => {
  return <Navigation title={title} />
}
export default Header
