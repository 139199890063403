import moment from 'moment-timezone'
import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import _, { extend } from 'lodash'
export { default as classNames } from 'classnames'
import { jsonToCSV } from 'react-papaparse'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Get key enum from value
export function getKeyByValue<
  TEnumKey extends string,
  TEnumVal extends string | number
>(myEnum: { [key in TEnumKey]: TEnumVal }, value: string): keyof typeof myEnum {
  const indexOfS = Object.values(myEnum).indexOf(value as TEnumVal)

  const key: any = Object.keys(myEnum)[indexOfS]

  return key
}

export function isEnumValue(value: any, enumeration: any): boolean {
  return Object.values(enumeration).includes(value)
}

interface NameMap {
  fullName: string
  shortName: string
}

export function convertToTitleCase(
  text: string,
  nameMaps: NameMap[] = []
): string {
  if (!text) {
    return ''
  }
  let result = text

  // Replace each fullName in the text with its shortName
  for (const { fullName, shortName } of nameMaps) {
    result = result.split(fullName).join(shortName)
  }

  // Convert the rest of the text to title case
  result = result
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

  return result
}

export function displayDateBeautiful(
  date: moment.MomentInput,
  format = 'DD/MM/YY HH:mm',
  timezone = moment.tz.guess()
) {
  if (!moment(date).isValid()) {
    return date?.toString() || ''
  }
  return moment(date).tz(timezone).format(format)
}

export const safeJsonParse = (json: string | undefined | null) => {
  try {
    return JSON.parse(json ?? '')
  } catch (e) {
    return null
  }
}

export function compareNumbersOrNull<T extends Record<string, any>>(
  a: T,
  b: T,
  key: string
): number {
  const valA = a[key]
  const valB = b[key]

  if (valA === null && valB === null) {
    return 0
  }
  if (valA === null) {
    return -1
  }
  if (valB === null) {
    return 1
  }

  // Compare as numbers
  return valA - valB
}

export function exportToCsv<T>(data: T[], filename: string) {
  try {
    const csv = jsonToCSV(data)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (err) {
    console.error('Error exporting to CSV:', err)
  }
}

export function updateEmptyStringsToNull<T extends Record<string, any>>(
  obj: T
): T {
  return _.mapValues(obj, (value) => (value === '' ? null : value)) as T
}

export * from './local'
