import { cva, type VariantProps } from 'class-variance-authority'
import React, { FC } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import { HomeIcon, BookOpenIcon, MonitorIcon } from 'lucide-react'
import { APP_HEADER_HEIGHT } from '@/constants/envs/constants'
import IceCubeLogo from '@/assets/image/ice_cube_logo.png'

const items: MenuProps['items'] = [
  {
    label: 'Home',
    key: 'home',
    icon: <HomeIcon size={18} />
  },
  {
    label: 'Order History',
    key: 'orderHistory',
    icon: <BookOpenIcon size={18} />
  },
  {
    label: 'Monitor',
    key: 'monitor',
    icon: <MonitorIcon size={18} />,
    children: [
      {
        label: 'Monitor 1',
        key: 'monitor1'
      },
      {
        label: 'Monitor 2',
        key: 'monitor2'
      }
    ]
  }
]
const navigationContainerVariants = cva(
  `md:pl-7 pl-2 sticky flex items-center gap-4 top-0 z-50 max-h-[110px] h-[${APP_HEADER_HEIGHT}px] w-full bg-white`,
  {
    variants: {
      variant: {
        default:
          'bg-gray-100 dark:bg-slate-900 border-b border-gray-200 dark:border-slate-800',
        transparent: ''
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

interface INavContainerProps
  extends VariantProps<typeof navigationContainerVariants> {
  title?: string
  children: React.ReactNode
}

interface INavProps extends VariantProps<typeof navigationContainerVariants> {
  title?: string
  rightElement?: React.ReactNode
}

const NavigationContainer: React.FC<INavContainerProps> = ({
  children,
  variant,
  title
}) => {
  return (
    <div className={navigationContainerVariants({ variant })}>
      <RouterLink
        to="/"
        className="flex items-center gap-4 text-nowrap whitespace-nowrap"
      >
        <img src={IceCubeLogo} width="26" />
        <p className="text-lg font-sm font-bold">{title}</p>
      </RouterLink>
      <div className="flex items-center justify-between gap-4 w-full">
        {children}
      </div>
    </div>
  )
}

export const Navigation: FC<INavProps> = ({ variant, title }) => {
  const navigate = useNavigate()
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'monitor1') {
      window.open('/monitor/1', '_blank')
    } else if (e.key === 'monitor2') {
      window.open('/monitor/2', '_blank')
    } else if (e.key === 'orderHistory') {
      navigate('/order-history')
    } else if (e.key === 'home') {
      navigate('/')
    }
  }

  return (
    <NavigationContainer variant={variant} title={title}>
      <Menu
        items={items}
        onClick={handleMenuClick}
        mode="horizontal"
        className="w-full grow"
      />
    </NavigationContainer>
  )
}
