import { useMemo, useReducer } from 'react'
import {
  TableName,
  Row,
  run,
  Change,
  Filter,
  SubscriptionStatus,
  TSubscriptionLifeCycleStatus
} from '../supabase.interface'

import { useEvent } from '@/hooks/useEvent'
import { useRealtimeChannel } from './useRealtime'
import { supabaseClient } from '@/configs/supabase'
import { applyChange } from '@/libs/supabase/realtime'

async function fetchSnapshot<T extends TableName>(
  table: T,
  filter?: Filter<T>
) {
  let q = supabaseClient.from(table).select('*')
  if (filter != null) {
    q = q.eq(filter.k, filter.v)
  }
  return (await run(q)).data as Row<T>[]
}

export interface State<T extends TableName> {
  status: TSubscriptionLifeCycleStatus
  rows?: Row<T>[]
  pending: Change<T>[]
}

type ActionBase<K, V = void> = V extends void ? { type: K } : { type: K } & V

type Action<T extends TableName> =
  | ActionBase<'ENABLED'>
  | ActionBase<'SUBSCRIBED'>
  | ActionBase<'FETCHED', { snapshot: Row<T>[] }>
  | ActionBase<'RECEIVED_CHANGE', { change: Change<T> }>
  | ActionBase<'RECEIVED_ERROR', { err?: Error }>
  | ActionBase<'DISABLED'>

const getReducer =
  <T extends TableName>(table: T) =>
  (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'ENABLED': {
        return { ...state, status: 'starting', pending: [] }
      }
      case 'SUBSCRIBED': {
        return { ...state, status: 'fetching', pending: [] }
      }
      case 'FETCHED': {
        let rows = action.snapshot
        for (const change of state.pending) {
          rows = applyChange(table, rows, change)
        }
        return { status: 'live', rows: rows, pending: [] }
      }
      case 'RECEIVED_CHANGE': {
        if (state.rows != null) {
          return {
            ...state,
            rows: applyChange(table, state.rows, action.change)
          }
        } else {
          return { ...state, pending: [...state.pending, action.change] }
        }
      }
      case 'RECEIVED_ERROR': {
        return { ...state, status: 'errored' }
      }
      case 'DISABLED': {
        return { ...state, status: 'disabled' }
      }
      default:
        throw new Error('Invalid action.')
    }
  }

export function useSubscription<T extends TableName>(
  table: T,
  filter?: Filter<T>,
  fetcher?: () => PromiseLike<Row<T>[] | undefined>,
  preload?: Row<T>[],
  filterString?: string,
  loadNewerQuery?: (
    rows: Row<T>[] | undefined
  ) => PromiseLike<Row<T>[] | undefined>
) {
  const fetch = fetcher ?? (() => fetchSnapshot(table, filter))
  const reducer = useMemo(() => getReducer(table), [table])
  const [state, dispatch] = useReducer(reducer, {
    status: 'starting',
    rows: preload,
    pending: []
  })
  const upsertRow = (r: Row<T>) =>
    dispatch({
      type: 'RECEIVED_CHANGE',
      change: {
        table,
        new: r,
        old: {},
        eventType: 'UPDATE' // really is an upsert
      } as any
    })

  const loadNewer = useEvent(async () => {
    const retryLoadNewer = async (attemptNumber: number): Promise<boolean> => {
      const newRows = await loadNewerQuery?.(state.rows)
      if (newRows?.length) {
        newRows.map(upsertRow)
        return true
      } else if (attemptNumber < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, 100 * attemptNumber))
        return retryLoadNewer(attemptNumber + 1)
      }
      return false
    }

    const maxAttempts = 10
    await retryLoadNewer(1)
  })

  const onChange = useEvent((change: Change<T>) => {
    dispatch({ type: 'RECEIVED_CHANGE', change })
  })

  const onStatus = useEvent((status: SubscriptionStatus, err?: Error) => {
    switch (status) {
      case 'SUBSCRIBED': {
        dispatch({ type: 'SUBSCRIBED' })
        fetch().then((snapshot) => {
          if (snapshot != undefined) dispatch({ type: 'FETCHED', snapshot })
        })
        break
      }
      case 'TIMED_OUT': {
        dispatch({ type: 'RECEIVED_ERROR', err })
        break
      }
      case 'CHANNEL_ERROR': {
        dispatch({ type: 'RECEIVED_ERROR', err })
        break
      }
      case 'CLOSED': {
        // nothing to do here
      }
    }
  })

  const onEnabled = useEvent((enabled: boolean) => {
    dispatch({ type: enabled ? 'ENABLED' : 'DISABLED' })
  })

  useRealtimeChannel(
    '*',
    table,
    filter,
    onChange,
    onStatus,
    onEnabled,
    filterString
  )
  return { ...state, loadNewer }
}
