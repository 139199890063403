import { createClient } from '@supabase/supabase-js'
const supabaseInstanceId = process.env.REACT_APP_SUPABASE_INSTANCE_ID
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export function getInstanceHostname(instanceId: string) {
  return `${instanceId}.supabase.co`
}

const createSupabaseClient = () => {
  if (!supabaseInstanceId)
    throw new Error(
      'Environment variables REACT_APP_SUPABASE_INSTANCE_ID undefined'
    )
  if (!supabaseAnonKey)
    throw new Error(
      'Environment variables REACT_APP_SUPABASE_ANON_KEY undefined'
    )
  const supabaseUrl = `https://${getInstanceHostname(supabaseInstanceId)}`
  return createClient(supabaseUrl, supabaseAnonKey)
}

export function updateSupabaseAuth() {
  supabaseClient['realtime'].setAuth(null)
}

export const supabaseClient = createSupabaseClient()
