import React, { FC } from 'react'
import { Route, Routes as RoutesWrap, Navigate } from 'react-router-dom'
import BackOffice from './pages/BackOffice'
import { MonitorDisplay } from './pages/MonitorDisplay'
import { OrderHistory } from './pages/OrderHistory'

export const Router: FC = () => {
  function RedirectPathToHomeOnly() {
    return <Navigate to="/" replace={true} />
  }
  return (
    <RoutesWrap>
      <Route path="/" element={<BackOffice />} />
      <Route path="/monitor/:monitorNo" element={<MonitorDisplay />} />
      <Route path="/order-history/" element={<OrderHistory />} />
      <Route path="*" element={RedirectPathToHomeOnly()} />
    </RoutesWrap>
  )
}
