import React, { useMemo } from 'react'
import { useSubscription } from '@/libs/supabase/hooks/useSubscription'
import _, { filter } from 'lodash'
import { findOrderBookFromMonitorNo } from '@/supabase/Orderbook/orderbook'

export const useMonitorOrderBook = (monitorNo: number) => {
  const shouldFetch = !_.isNil(monitorNo)
  const { rows } = useSubscription(
    'OrderBook',
    undefined,
    shouldFetch ? () => findOrderBookFromMonitorNo(monitorNo) : undefined
  )

  const data = shouldFetch
    ? useMemo(() => {
        return filter(rows, {
          monitor_number: monitorNo,
          status: 'OPEN',
          is_monitor_display: true
        })?.[0]
      }, [rows])
    : undefined

  return data
}
