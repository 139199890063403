import { Row } from '@/libs/supabase/supabase.interface'
import { SupabaseClient } from '@supabase/supabase-js'

export async function getVehicleLicensePlateId(db: SupabaseClient) {
  const { data } = await db
    .from('VehicleLicensePlate')
    .select('id')
    .is('deleted_at', null)

  return data as Row<'VehicleLicensePlate'>[]
}
