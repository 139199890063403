import React, { FC } from 'react'
import { Row } from '@/libs/supabase/supabase.interface'
import {
  compareNumbersOrNull,
  convertToTitleCase,
  displayDateBeautiful
} from '@/utils'
import { Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { TablePaginationConfig } from 'antd/es/table'
import moment from 'moment-timezone'
import { Enums } from '@/configs/supabase/schema'
import _ from 'lodash'

interface IOrderHistoryTableProps {
  orderHistories: Row<'OrderBook'>[] | undefined
  currentPage: number
  itemsPerPage: number
  totalItems: number
  handleTableChange: (pagination: TablePaginationConfig) => void
}

export const OrderHistoryTable: FC<IOrderHistoryTableProps> = ({
  orderHistories = [],
  currentPage,
  itemsPerPage,
  totalItems,
  handleTableChange
}) => {
  const columns: ColumnsType<Row<'OrderBook'>> = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      width: 110
    },
    {
      title: 'License Plate',
      dataIndex: 'vehicle_license_plate_id',
      key: 'vehicle_license_plate_id',
      align: 'center',
      width: 300,
      sorter: {
        compare: (a, b) =>
          a.vehicle_license_plate_id.localeCompare(b.vehicle_license_plate_id)
      }
    },
    {
      title: 'ใหญ่',
      dataIndex: 'large_ice_cube_amount',
      key: 'large_ice_cube_amount',
      width: 100,
      render: (amount) => (_.gt(amount, 0) ? amount : '-'),
      sorter: {
        compare: (a, b) => compareNumbersOrNull(a, b, 'large_ice_cube_amount')
      }
    },
    {
      title: 'เล็ก',
      dataIndex: 'small_ice_cube_amount',
      key: 'small_ice_cube_amount',
      width: 100,
      render: (amount) => (_.gt(amount, 0) ? amount : '-'),
      sorter: {
        compare: (a, b) => compareNumbersOrNull(a, b, 'small_ice_cube_amount')
      }
    },
    {
      title: 'โม่',
      dataIndex: 'crushed_ice_amount',
      key: 'crushed_ice_amount',
      width: 100,
      render: (amount) => (_.gt(amount, 0) ? amount : '-'),
      sorter: {
        compare: (a, b) => compareNumbersOrNull(a, b, 'crushed_ice_amount')
      }
    },
    {
      title: 'มย',
      dataIndex: 'coarsely_crushed_ice_amount',
      key: 'coarsely_crushed_ice_amount',
      width: 100,
      render: (amount) => (_.gt(amount, 0) ? amount : '-'),
      sorter: {
        compare: (a, b) =>
          compareNumbersOrNull(a, b, 'coarsely_crushed_ice_amount')
      }
    },
    {
      title: 'Monitor',
      dataIndex: 'monitor_number',
      key: 'monitor_number',
      width: 100,
      sorter: {
        compare: (a, b) => compareNumbersOrNull(a, b, 'monitor_number')
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (status: Enums<'OrderBookStatus'>) => {
        let color
        switch (status) {
          case 'OPEN':
            color = 'processing'
            break
          case 'COMPLETED':
            color = 'success'
            break
          case 'DELETED':
            color = 'error'
            break
        }
        return (
          <Tag color={color} className="text-sm">
            {convertToTitleCase(status)}
          </Tag>
        )
      },
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status)
      },
      filters: [
        {
          text: convertToTitleCase('OPEN'),
          value: 'OPEN'
        },
        {
          text: convertToTitleCase('COMPLETED'),
          value: 'COMPLETED'
        },
        {
          text: convertToTitleCase('DELETED'),
          value: 'DELETED'
        }
      ],
      defaultFilteredValue: ['OPEN', 'COMPLETED', 'DELETED'],
      filterMultiple: true,
      onFilter: (value, record) => record.status.indexOf(value as string) === 0
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (date) => displayDateBeautiful(moment.utc(date)),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix()
    },
    {
      title: 'Completed At',
      dataIndex: 'completed_at',
      key: 'completed_at',
      width: 200,
      render: (date) =>
        moment(date).isValid() ? displayDateBeautiful(moment.utc(date)) : '-',
      sorter: (a, b) =>
        moment(a.completed_at).unix() - moment(b.completed_at).unix()
    }
  ]

  return (
    <div className="flex flex-col">
      <div style={{ overflowX: 'auto' }}>
        <Table
          columns={columns}
          dataSource={orderHistories}
          rowKey="id"
          locale={{ emptyText: 'No data available' }}
          pagination={{
            current: currentPage,
            pageSize: itemsPerPage,
            total: totalItems,
            showSizeChanger: true
          }}
          bordered={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  )
}
